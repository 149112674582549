import React from "react"

import NavBar from "../component/NavBar"

import "./Contact.css"

class Contact extends React.Component {

  render() {
    return(
      <div className="Page">
        <NavBar showLogo={true} />
        <div id="Contact" className="Content Veiling">
          <div id="ContactContainer">
            <div id="ContactContainerVeiling">
              <div className="ContactBox">
              </div>
              <div className="ContactBox">
              </div>
              <div className="ContactBox">
                <p>Subosits Tamás</p>
                <p>06 70 318 3333</p>
                <p> subositstamas@rubinpince.hu</p>
              </div>
              <div className="ContactBox">
                <p>Marghescu Mária</p>
                <p>06 30 202 0842</p>
                <p>marghescune.hunor.maria@gmail.com</p>
              </div>
              <div className="ContactBox">
                <p>Deliné Szita Anna</p>
                <p>06 70 586 9361</p>
                <p>anna.szita@gmail.com</p>
              </div>
              <div className="ContactBox">
              </div>
              <div className="ContactBox">
                <a href="https://www.facebook.com/Zen-Do-Budapest-112710940586764">
                  <div className="ContactBoxFb">
                    <p>Facebook</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
